<template>
  <div class="container" style="line-height: 1;">
    <div class="row">
      <NotLoggedInNotification v-if="Object.entries(this.$store.getters.getUser).length === 0"></NotLoggedInNotification>
    </div>
      <div class="row my-osa-container">        
    <TheMyOSAComponent v-if="Object.entries(this.$store.getters.getUser)" @osa-overview-clicked="scrollToOverview()"></TheMyOSAComponent>
      </div>
    <div class="row">
      <h1>Online-Self-Assessment (OSA) der TU Wien</h1>
    </div>
    <div class="row">
      <p>
        Das Online Self Assessment, oder kurz OSA, ist ein Tool, welches Sie bei einer möglichen Studienwahl unterstützen soll. Dabei werden Ihnen verschiedene Inhalte präsentiert, die Sie zu einer Selbstreflexion anregen sollen, 
        damit Sie eine informierte Entscheidung für oder gegen ein Studium treffen können. Innerhalb des OSA werden Sie grundlegende Information und Aufgaben eines Studiums der TU Wien kennen lernen. Sie werden dabei mit Hilfe von 
        interaktiven Beispielen Ihre persönlichen Interessen überprüfen und sollen dadurch erkennen, ob Sie entsprechende Motivation für das Erlernen und Bearbeiten von Inhalten und Problemstellungen Ihres Wunschstudiums mitbringen.</p>
      <p>
        Das Online Self Assessment ist jedoch nicht als Test zu verstehen, den Sie vor dem Studienbeginn absolvieren und bestehen müssen. 
        Stattdessen soll das OSA Ihnen etwas mehr Klarheit über Studien- und Berufsalltag vermitteln und Ihnen so eine Möglichkeit bieten, Ihre Eignung und Begeisterung für das Studium und dessen Inhalte selbst einzuschätzen.</p>

      <p>Dazu werden Sie, in voneinander unabhängigen Modulen, in einzelne Themengebiete eines Studiums eingeführt, bearbeiten dazu passende Aufgabenstellungen und können eine Selbsteinschätzung zu Ihren Fähigkeiten und Interessen abgeben. 
        Nach Abschluss aller Module wird Ihnen ein individualisiertes Feedback präsentiert, dass Sie in eine fundierte Studienentscheidung einbeziehen können.</p>
    </div>
    <div class="row">
    <img :src="textImage" alt="Computerarbeit" style="width: 100%; padding: 40px 60px 40px 60px;"/>
  </div>
    <div class="row">
      <h2>Wie es funktioniert</h2>
    </div>
    <div class="row">
      <p>
        <!--
        Sie können Online Self Assessments mehrerer Studien absolvieren. Die Ergebnisse eines OSA eines gegebenen
        Studiums hat keinen Einfluss auf die Ergebnisse eines OSAs eines anderen Studiums.-->
      </p>
      <p>Für das OSA ist keine fachliche Vorbereitung notwendig. Das OSA kann unterbrochen und später fortgesetzt werden, aber es wird empfohlen, es in einer Sitzung zu absolvieren.</p>
      <p>Nach Auswahl des OSA für ein Studium sehen Sie eine Gruppe von unabhängigen Modulen, die von Ihnen vollständig bearbeitet werden müssen. Einige dieser Module stellen die Experimente dar, die jeweils aus folgenden drei Punkten bestehen:</p>
     <ul style="padding-left: 50px;">
        <li>einer kurzen Einführung in einen Themenbereich des Studiums,</li>
        <li>interaktiv zu bearbeitenden Aufgabenstellungen aus diesem Bereich,</li>
        <li>als auch zu weiterführenden Informationen</li>
        <!--<li>sowie einem Fragebogen zu Ihren Selbsteinschätzungen</li>-->
      </ul>
      <p>Für die Bearbeitung der Experimente
      müssen Sie im OSA angemeldet sein.
      </p>
      <p>Die einzelnen Module können in beliebiger Reihenfolge bearbeitet werden, aber da manche Inhalte aufbauend sein
        können, empfiehlt es sich, die vorgeschlagene Reihenfolge der Module einzuhalten. <!--Nach Bearbeitung aller Module werden Ihnen Ihre Ergebnisse präsentiert und das OSA des jeweiligen Studiums gilt
        als abgeschlossen.--></p>
    </div>
    <div class="user-study-comment">Im Zuge der User Study befindet sich am Ende jedes Moduls auch ein Link zu einem Fragebogen.</div>
    <div class="row">
      <h3>Dauer</h3>
    </div>
    <div class="row">
      <p>
        Für eine vollständige Bearbeitung des OSA sollten Sie ca. 90 - 120 Minuten vorsehen. Falls nicht anders
        möglich, können Sie zwischen den einzelnen Modulen unterbrechen und später fortsetzen. Wir empfehlen jedoch das
        OSA in einem Durchlauf komplett zu bearbeiten. <!--Die Informationsteile und Ihre Ergebnisse können Sie immer wieder
        einsehen, die Aufgaben und Fragebögen bereits abgeschlossener Module jedoch nicht wiederholt bearbeiten.-->
      </p>
    </div>
    <div class="row">
      <p style="font-weight: bold">
          Da die Aufgaben für große Bildschirme optimiert sind, empfehlen wir
          Ihnen das OSA auf einem Gerät mit ausreichender Bildschirmgröße
          durchzuführen.
        </p>
    </div>
    <div id="osa-overview" class="row">
      <h2>Alle Online-Self-Assessment im Überblick</h2>
    </div>

    <div class="row card-grid">
      <!--
      <div class="card disabled col-md-4 col-lg-3 my-5 mx-3" style="width: 15rem">
        <img :src="cardImgArchitektur" class="card-img-top" alt="..." />
        <div class="card-body">
          <div class="card-title">Architektur</div>
        </div>
      </div>
-->
      <div class="card col-md-4 col-lg-3 my-5 mx-3" style="width: 15rem">
        <router-link to="/informatik"><img :src="cardImgInformatik" class="card-img-top" alt="Bild OSA Informatik" />
          <div class="card-body">
            <div class="card-title">Informatik</div>
          </div>
        </router-link>
      </div>
      
      <div class="card col-md-4 col-lg-3 my-5 mx-3" style="width: 15rem" v-if="false">
        <router-link to="/showcase">
        <img :src="cardImgRaumplanung" class="card-img-top" alt="..." />
        <div class="card-body">
          <div class="card-title">Showcase & Playground</div>
        </div>
        
      </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NotLoggedInNotification from "@/components/NotLoggedInNotification.vue";
import TheMyOSAComponent from "@/components/TheMyOSAComponent.vue";
import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  name: "OSALandingPage",
  components: {
    NotLoggedInNotification,
    TheMyOSAComponent
},
  data() {
    return {
      textImage: require("../assets/res/lehre4_c_tu_wien.jpg"),
      cardImgArchitektur: require("../assets/res/card_img_architektur.png"),
      cardImgInformatik: require("../assets/res/card_img_informatik.png"),
      cardImgRaumplanung: require("../assets/res/card_img_raumplanung.png"),
      cardImgBauingenieurwesen: require("../assets/res/card_img_bauingenieurwesen.png")
    };
  },
  mounted() {
    scrolling.scrollToTop();
    this.$store.dispatch('setHeaderPageTitle', 'Online Self Assessment Informatik');
    this.$store.dispatch('setIsUserInAssignment', false);
  },
  methods: {
    scrollToOverview(){
      scrolling.scrollToElementById('osa-overview')
    }
  },
};
</script>

<style >
.card-grid {
  display: flex;
  justify-content: space-between;
}

.card {
  border: 3px solid #707070;
  padding: 10px;
  font-size: 1.3em;
}

.card:hover {
  border: 3px solid black;
  text-decoration: underline;
  color: #00446e;
}

.disabled {
  background-color: lightgray;
}

.card-body {
  display: flex;
  align-content: center;
  justify-items: center;

}

.card-title {
  font-size: 1.2em;
  color: #006699;
  font-family: "PT Sans", sans-serif;
  margin: auto;
}

a {
  text-decoration: none;
}
p{
  line-height: 1.3;
}

</style>
