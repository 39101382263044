<template>
  <div class="container">
      <div class="not-logged-in">
        <div class="not-logged-in-container row">
          <div class="message col-md-6 col-6">
            Sie sind derzeit nicht eingeloggt. Einige Inhalte stehen Ihnen daher
            nicht zur Verfügung.
          </div>
          <div class="col-md-4 col-4">
            <router-link to="/">
              <div class="custom-btn">
                <i
                  class="bi bi-box-arrow-in-right"
                  style="margin-right: 10px"
                ></i>Zum Login
              </div></router-link
            >
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "NotLoggedInNotification",
  data() {
    return {
    };
  },
  mounted() {},
  props: {},
  emits: [],
  computed: {},
  methods: {},
};
</script>

<style scoped>
.not-logged-in {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.not-logged-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #006bac;
}

.message {
  font-size: 15px;
}
</style>
